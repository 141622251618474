<template>
  <main class="joyful-movement">

    <!-- Hero image -->
    <section class="hero">
      <img
        src="@/assets/images/JMWLP_studio-page-banner.jpg"
        srcset="
          @/assets/images/JMWLP_studio-page-banner.jpg 1x,
          @/assets/images/JMWLP_studio-page-banner@2x.jpg 2x,
          @/assets/images/JMWLP_studio-page-banner@3x.jpg 3x
        "
        alt="Joyful Movement Whole Life Program powered by Grown Women Dance Collective"
        class="hero-image"
      />
    </section>

    <!-- Donate button -->
    <section class="donate">
      <a 
        href="https://www.flipcause.com/secure/cause_pdetails/MTE2NzQ2"
        target="_blank"
        class="donate-button">
        Donate Now   
      </a>
      <!-- <p class="donate-text">Balanced Body is offering a match of $50,000 in the month of June for Joyful Movement Whole Life Scholarship Program. All donations will be matched (not just classes!) All donations are tax-deductible and donors will receive emailed receipt with 503c charitable contribution information.</p> -->
    </section>

    <!-- Summary -->
    <section class="summary">
      <!-- <h1 class="summary-title">Joyful Movement Whole Life Program Scholarship launching Sept 2021!</h1> -->
      <div class="summary-line"></div>
      <p class="summary-quote"><span style="font-style: italic;">“With the world watching, and the nation finally awake, if there is will, bravery, action, and a genuine commitment to listening to the voices of those most impacted, we can collectively create a world full of real possibilities, hope and justice.”</span>	—  Tonya Amos</p>
      <p class="summary-description">
        We are partnering with Tonya Amos of <a href="https://www.grownwomendance.org/training-and-certification" taget="_blank">Grown Women Dance Collective</a> to establish the Joyful Movement Whole Life Program, a scholarship program to train and mentor residents of disinvested neighborhoods to become audacious movement educators and leaders in their communities.  
        <br><br>
        The two year certification program includes Comprehensive and Post-Rehabilitation Pilates teacher training, danced-based movement, mental health counseling, nutrition, entrepreneurship skills, paid internships, and job placement assistance. Launching in Sept 2021, the Joyful Movement Program will leverage community-based expertise to help break the cycle of intergenerational poverty; and impact health disparities in Black and Brown communities. 
        <br><br>
        A program of Grown Women Dance Collective, fiscally sponsored by 501c3 Dancers Group, the Joyful Movement Whole Life Program is funded solely by grants, corporate partners and our Pilates community! 
      </p>
    </section>

    <!-- Summary -->
    <section class="video">
      <div class="video-container">
        <a href="https://youtu.be/nCQX64LGfpM" target="_blank" class="video-thumbnail">
          <img 
            src="@/assets/images/joyfulmovement-video@1x.png"
            alt="Video thumbnail of woman talking about Joyful Movement Whole Life Program"
            class="video-preview-image"
          />
          <img 
            src="@/assets/images/video-play-icon.svg"
            alt="Play icon"
            class="video-play-icon"
            tabindex="0"
            role="button"
          />
        </a>
        <div class="video-text-container">
          <h2 class="video-title">Fundraising Classes for Joyful Movement Whole Life Program!</h2>
          <p class="video-description">
            <span style="font-weight: 700;">How can YOU get involved?</span>  We are asking all interested Studios to hold 1 fundraiser class during the month of June.
            <br><br>
            You set the date, time and decide in-person or virtual (depending on local COVID regulations.) Instead of “buying” a class, your clients donate directly to the Joyful Movement Whole Life Program using the “donate now” button.  Each donor will receive a tax deductible receipt. 
            <br><br>
            <span style="font-weight: 700;">BONUS</span> -- Balanced Body is matching dollar-for-dollar donations from your fundraising class!  
            <br><br>
            <span style="font-weight: 700;">Ready to plan YOUR fundraising class?</span> Look below for graphics and suggested copy to get you started!
          </p>

          <!-- Mobile -->
          <div class="video-line"></div>
          <p class="video-contact">To get started or for more information, contact Tonya Amos at <a href="mailto:Tonya@GrownWomenDance.org">Tonya@GrownWomenDance.org</a> or <a href="tel:9256804400">(925) 680 4400</a>.</p>
        </div>
      </div>
    </section>

    <!-- Resources -->
    <section class="resources">
      <div class="resources-container">
        <h2 class="resources-title">Joyful Movement Fundraising Class – Marketing Resources</h2>
        <p class="resources-description">
          <span style="font-weight: 700;">How to create "Donate" button for Your Fundraising Class:</span><br>
          <ol class="resources-list">
            <li>Set-up your client class in your Studio operating system</li>
            <li>Instead of charging a class fee, use  “Donate to Joyful Movement” hyperlinked to <a href="https://www.flipcause.com/secure/cause_pdetails/MTE2NzQ2" target="_blank" style="color: #EADD9D !important;">https://www.flipcause.com/secure/cause_pdetails/MTE2NzQ2</a></li>
            <li>Be sure your clients enter your Studio Name on the donor line so we can track your Studio's donation</li>
            <li>ALL funds go direct to the Joyful Movement Whole Life Program (via the Grown Women Dance Collective a fiscally sponsored project of Dancers' Group 5013C)</li>
          </ol>
          Any questions? Email us! <a href="mailto:balancedbody@pilates.com" style="color: #EADD9D !important;">balancedbody@pilates.com</a>
        </p>
        <div class="resources-button-container">
          <a href="https://s3.amazonaws.com/s3.pilates.com/scholarship/Joyful_Movement_Whole_Life_Program_Assets.zip" download class="resources-button">
            <p class="resources-button-text">Download Social Media Graphics & Copy</p>
            <svg width="44" height="44" style="min-width: 44px;">
              <path class="download-svg" d="M31,24v4c0,0.5-0.2,1-0.6,1.4C30,29.8,29.5,30,29,30H15c-0.5,0-1-0.2-1.4-0.6C13.2,29,13,28.5,13,28v-4"/>
              <path class="download-svg" d="M17,19l5,5l5-5"/>
              <path class="download-svg" d="M22,24V12"/>
              <circle class="download-svg" cx="22" cy="22" r="21"/>
            </svg>
          </a>
          <a href="https://s3.amazonaws.com/s3.pilates.com/scholarship/Joyful_Movement_Whole_Life_Program_Site.zip" download class="resources-button">
            <p class="resources-button-text">Download Assets for Your Fundraising Class Registration Page</p>
            <svg width="44" height="44" style="min-width: 44px;">
              <path class="download-svg" d="M31,24v4c0,0.5-0.2,1-0.6,1.4C30,29.8,29.5,30,29,30H15c-0.5,0-1-0.2-1.4-0.6C13.2,29,13,28.5,13,28v-4"/>
              <path class="download-svg" d="M17,19l5,5l5-5"/>
              <path class="download-svg" d="M22,24V12"/>
              <circle class="download-svg" cx="22" cy="22" r="21"/>
            </svg>
          </a>
        </div>
      </div>
    </section>

    <!-- Testimonial -->
    <section class="testimonial">
      <img 
        src="@/assets/images/joyfulmovement-logo.svg"
        alt="Joyful Movement Logo"
        class="testimonial-leaf"
      />

      <div class="testimonial-content-container">
        <img 
          src="@/assets/images/single-quote.svg"
          alt="quote icon"
          class="testimonial-quote"
        />
        <p class="testimonial-text">
          When I first became aware of the systemic racial inequality in this country, I was dismayed, saddened, and heartsick.  I wanted to do something to help change things for the better.  When I learned about the Joyful Movement Program, I decided to put my actions where my heart is.
          <br><br>
          Our fundraiser classes have been going for 3 months now.  They are a part of “who” my studio is.  I believe we all need to open our hearts and take measurable action to create a more equitable society in which every person has the same chance for success.
        </p>
        <div class="testimonial-line"></div>
        <p class="testimonial-name testimonial-name-bold">Gwen Miller</p>
        <a href="https://www.gwenmillerstudio.com/" target="_blank" class="testimonial-name">The Gwen Miller Studio</a>
      </div>
      
      <!-- Company -->
      <!-- <div class="company-card">
        <img 
          class="company-logo"
          src="https://via.placeholder.com/131x34"
          alt="ph"
        />
        <p class="company-quote">“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.”</p>
        <div class="company-line"></div>
        <p class="company-name">Company Name XYZ</p>
      </div> -->
    </section>

    <!-- Contact -->
    <section class="contact">
      <p style="font-weight: 700; text-transform: uppercase;">We are here to help!</p>
      <p>Questions about the graphics or how to use them? Please <a href="mailto:balancedbody@pilates.com">contact us.</a></p>
      <p>Questions about holding a fundraising class? Please contact <a href="mailto:Tonya@GrownWomenDance.org">Tonya Amos.</a></p>
    </section>
  

  </main>
</template>

<script>
  export default {
    name: "JoyfulMovement",

    metaInfo: {
      title: "Joyful Movement Whole Life Program",
      titleTemplate: "%s | Diversity in Pilates",
      meta: [
        {
          name: "description",
          content: "We are partnering with Tonya Amos of Grown Women Dance Collective to establish the Joyful Movement Whole Life Program, a scholarship program to train and mentor residents of disinvested neighborhoods to become audacious movement educators and leaders in their communities."
        }
      ]
    },
  }
</script>

<style scoped>
.joyful-movement {
  font-size: 16px;
}

.hero {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 64px;
}

.hero-image {
  max-width: 1164px;
  width: 100%;
}

.summary {
  max-width: 960px;
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;
  color: #3E3C53;
  margin-bottom: 64px;
}

.summary-title {
  font-size: 36px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  margin-bottom: 32px;
}

.summary-line {
  height: 0px;
  width: 158px;
  border-top: 4px dotted #9EB675;
  margin-bottom: 32px;
}

.summary-quote {
  font-size: 20px;
  font-weight: 300;
  font-family: "Merriweather", sans-serif;
  line-height: 32px;
  margin-bottom: 20px;
}

.summary-description {
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  line-height: 29px;
  color: #555658;
}

.video {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #3E3C53;
  background-color: #F2F0EC;
  padding: 64px 16px;
}

.video-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 980px;
  width: 100%;
}

.video-thumbnail {
  position: relative;
}

.video-preview-image {
  min-width: 480px;
  width: 100%;
}

.video-play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 112px;
  filter: drop-shadow( 0px 0px 4px rgba(0, 0, 0, 0.25));
}

.video-text-container {
  width: 100%;
  padding: 32px;
}

.video-title {
  font-size: 26px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  line-height: 32px;
  margin-bottom: 16px;
}

.video-description {
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  line-height: 24px;
}

.video-line {
  display: none;
  width: 158px;
  height: 0px;
  border-top: 4px dotted #ABAB78;
  margin-bottom: 32px;
}

.video-contact {
  display: none;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  line-height: 24px;
  color: #555658;
}

.video-contact a {
  text-decoration: underline;
  color: #555658;
}

.resources {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #958B85;
  color: #fff;
  padding: 110px 16px;
  margin-bottom: 110px;
}

.resources-container {
  max-width: 808px;
  width: 100%;
  margin: 0 auto;
}

.resources-title {
  font-size: 25px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  color: #EADD9D;
  margin-bottom: 24px;
}

.resources-description {
  font-family: "Roboto", sans-serif;
  line-height: 21px;
  margin-bottom: 32px;
  color: #EADD9D;
}

.resources-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.resources-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  min-height: 156px;
  padding: 46px 40px;
  margin-right: 30px;
  border-radius: 8px;
  border: 2px solid #fff;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  color: #fff;
  text-decoration: none;
  transition: 0.4s;
}

.resources-button:last-of-type {
  margin-right: 0;
}

.resources-button img {
  width: 44px;
}

.resources-button-text {
  margin-right: 40px;
}

.resources-list {
  list-style: decimal;
  margin-left: 28px;
  margin-top: 8px;
  margin-bottom: 28px;
}

.resources-list li {
  margin-bottom: 8px;
}

.resources-list li a {
  color: #fff !important;
}

.testimonial {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 1120px;
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;  
  color: #555658;
  margin-bottom: 110px;
}

.testimonial-leaf {
  width: 100%;
  max-width: 300px;
  margin-right: 50px;
}

.testimonial-content-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.testimonial-quote {
  width: 38px;
  margin-bottom: 24px;
}

.testimonial-text {
  font-family: "Roboto", sans-serif;
  font-size: 19px;
  line-height: 30px;
  margin-bottom: 30px;
}

.testimonial-line {
  width: 69px;
  height: 0;
  border-top: 4px dotted #9EB675;
  margin-bottom: 20px;
}

.testimonial-name {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  color: #919191;
  margin-bottom: 10px;
}

/* .testimonial-name:last-of-type {
  margin-bottom: 0;
} */

.testimonial-name-bold {
  font-weight: 700;
  text-transform: uppercase;
}

.company-card {
  width: 33%;
  margin-right: 60px;
}

.company-card:last-of-type {
  margin-right: 0;
}

.company-logo {
  margin-bottom: 16px;
}

.company-quote {
  font-family: "Roboto", sans-serif;
  line-height: 21px;
  margin-bottom: 32px;
}

.company-line {
  height: 0px;
  width: 158px;
  border-top: 4px dotted #ABAB78;
  margin-bottom: 16px;
}

.company-name {
  font-size: 14px;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
}

.contact {
  padding: 32px 16px;
  border-top: 1px solid #919191;
  border-bottom: 1px solid #919191;
  margin: 0 auto;
  text-align: center;
  color: #fff;
  background: linear-gradient(90deg, #30B252 0%, #208B3C 100%);
}

.contact p {
  margin-bottom: 10px;
  line-height: 22px;
}

.contact a {
  text-decoration: underline;
  color: #fff;
}

.download-svg {fill:none;stroke:#FFFFFF;stroke-width:2;stroke-linecap:round;stroke-linejoin:round; transition: 0.4s;}

.resources-button:hover {
  border: 2px solid #EADD9D;
}

.resources-button:hover  .download-svg {
  stroke: #EADD9D;
}

.donate {
  max-width: 960px;
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;
  color: #3E3C53;
  margin-bottom: 32px;
}

.donate-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  padding: 16px 24px;
  background: linear-gradient(90deg, #30B252 0%, #208B3C 100%);
  color: #fff;
  border-radius: 8px;
  width: 150px;
  text-decoration: none;
  bottom: 0;
  transition: 0.3s;
}

.donate-button:hover {
  bottom: 4px;
}

.donate-text {
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  line-height: 29px;
  color: #555658;
}

@media only screen and (max-width: 800px) {
  .hero {
    padding: 0;
  }

  .hero,
  .summary,
  .resources,
  .testimonial {
    margin-bottom: 48px;
  }

  .summary-title {
    font-size: 30px;
  }

  .summary-line {
    width: 60px;
  }

  .video {
    padding: 48px 20px
  }

  .video-thumbnail {
    max-width: 480px;
    width: 100%;
    margin-bottom: 16px;
  }

  .video-preview-image {
    max-width: 480px;
    width: 100%;
    min-width: 0;
  }

  .video-container {
    flex-direction: column;
    align-items: center;
  }

  .video-text-container {
    padding: 0;
  }

  .video-description {
    margin-bottom: 32px;
  }

  .video-line {
    display: block;
  }

  .video-contact {
    display: block;
  }

  .resources {
    padding: 48px 20px;
  }

  .resources-button-container {
    flex-direction: column;
  }

  .resources-button {
    max-width: 365px;
    width: 100%;
    margin-right: 0;
    margin-bottom: 16px;
  }

  .resources-button:last-of-type {
    margin-bottom: 0;
  }

  .resources-button-text {
    font-size: 16px;
    margin-right: 20px;
  }

  .testimonial {
    flex-direction: column;
  }

  .testimonial-leaf {
    margin-right: 0;
    margin-bottom: 30px;
  }

  .company-card {
    margin-right: 0;
    margin-bottom: 32px;
    max-width: 365px;
    width: 100%;
  }

  .company-card:last-of-type {
    margin-bottom: 0;
  }
}

</style>
